import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Footer.css";
import { Link } from "../../components";
import { PRIVACY_ROUTE, DISCLAIMER_ROUTE, TERMS_ROUTE } from "../../routes";
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";

class Footer extends React.Component {
  render() {
    return (
      <div className={s.footer}>
        <div className={s.rightMenu}>
          <a href="https://www.instagram.com/blendprecisely/" target="_blank" rel="nofollow noreferrer" className={s.Link}>
            <FaInstagram className={s.Icon} />
          </a>
          <a href="https://www.facebook.com/groups/blendprecisely/" target="_blank" rel="nofollow noreferrer" className={s.Link}>
            <FaFacebook className={s.Icon} />
          </a>
          <a href="https://www.youtube.com/channel/UCspY_ztRZc2kMpRf2MJLyMw" target="_blank" rel="nofollow noreferrer" className={s.Link}>
            <FaYoutube className={s.Icon} />
          </a>
        </div>

        <div className={s.leftMenu}>
          {<div className={s.copyright}>{"© 2019-2024 The Healthy Watt LLC. All Rights Reserved."}</div>}
          <span>
            <Link className={s.privacyLink} to={PRIVACY_ROUTE}>
              <span className={s.contact}>{"Privacy Policy"}</span>
            </Link>
          </span>
          <span>
            <Link className={s.privacyLink} to={DISCLAIMER_ROUTE}>
              <span
                className={s.contact}
                // href="mailto:info@PetalandStem.com"
              >
                {"Disclaimer"}
              </span>
            </Link>
          </span>
          <span>
            <Link className={s.privacyLink} to={TERMS_ROUTE}>
              <span className={s.faq}>{"Terms & Conditions"}</span>
            </Link>
          </span>
          <span>
            {/* <a href="mailto:hello@blendprecisely.io" style={{ color: 'inherit' }}>Contact Us</a> */}
            <a href="https://academy.blendprecisely.io/contact" target="_blank" style={{ color: "inherit" }} rel="noreferrer">
              Contact Us
            </a>
          </span>
        </div>
        <div className={s.middle}></div>
      </div>
    );
  }
}

export default withStyles(s)(Footer);
