import React, { useEffect } from "react";

const BuyAssistant = () => {
  useEffect(() => {
    // Dynamically load the Stripe Buy Button script
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <stripe-buy-button
      buy-button-id="buy_btn_0QLnsjYck53rcHs4ypjEApXY"
      publishable-key="pk_live_8QIyMJraj1KuT3MGjiOcx234"
    ></stripe-buy-button>
  );
};

export default BuyAssistant;
