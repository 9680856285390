import { defineMessages } from "react-intl";

export default defineMessages({
  home: {
    id: "header.home",
    defaultMessage: "HOME",
  },
  login: {
    id: "header.login",
    defaultMessage: "Login",
  },
  register: {
    id: "header.register",
    defaultMessage: "Sign Up",
  },
  logout: {
    id: "header.logout",
    defaultMessage: "Sign Out",
  },
  account: {
    id: "header.account",
    defaultMessage: "Account",
  },
  preferences: {
    id: "header.preferences",
    defaultMessage: "Preferences",
  },
  unsaved: {
    id: "header.unsaved",
    defaultMessage: "Unsaved changes will be lost.  Continue?",
  },
});
